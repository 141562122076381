.home-container {
  height: 100vh;
  width: 100%;
  position: relative;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  .text-container {
    height: 100%;
    width: 100%;

    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    color: white;
    text-decoration: none;

    h1 {
      font-family: "GT America Extended Black", sans-serif;
      font-size: 15.5vw;
    }

    &:hover {
      cursor: pointer;
    }
  }
}