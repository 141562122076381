.images::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.images {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  overflow-y: auto;
  position: relative;

  @keyframes slideInFromBottom {
    0% {
      transform: translateY(100px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .slide-in-animation {
    animation: 0.5s ease 0s 1 slideInFromBottom;
    animation-fill-mode: forwards;
  }

  .about1 {
    width: 75%;
    position: absolute;
    top: 10px;
    left: 50px;
    opacity: 0;
    animation: 0.5s ease 0s 1 slideInFromBottom;
    animation-fill-mode: forwards;
  }

  .about3 {
    width: 45%;
    position: absolute;
    top: 300px;
    right: 0;
    opacity: 0;
    animation: 0.5s ease 0.25s 1 slideInFromBottom;
    animation-fill-mode: forwards;
  }

  .about4 {
    width: 45%;
    position: absolute;
    top: 700px;
    left: 0;
    opacity: 0;
    animation: 0.5s ease 0.5s 1 slideInFromBottom;
    animation-fill-mode: forwards;
  }

  .about2 {
    width: 60%;
    position: absolute;
    top: 1100px;
    right: 70px;
    opacity: 0;
  }

  .empty-div {
    height: 1px;
    width: 1px;
    position: absolute;
    top: 1540px;
  }
}