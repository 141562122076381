html, body, #root {
    height: 100%;
    font-family: "Helvetica", sans-serif;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }
}

@font-face {
    font-family: 'GT America Extended Black';
    src: url('../public/fonts/GT-America-Extended-Black.woff2') format('woff2'),
    url('../public/fonts/GT-America-Extended-Black.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica';
    src: url('../public/fonts/Helvetica.woff2') format('woff2'),
    url('../public/fonts/Helvetica.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica';
    src: url('../public/fonts/Helvetica-Bold.woff2') format('woff2'),
    url('../public/fonts/Helvetica-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Helvetica';
    src: url('../public/fonts/Helvetica-BoldOblique.woff2') format('woff2'),
    url('../public/fonts/Helvetica-BoldOblique.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}