.product {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 65vh;
  width: 20vh;

  .product-image {
    height: 100%;
    object-fit: cover;
  }

  .tee-shirts-image {
    height: 100%;
    background-image: url("../../../public/images/eshop/tee-shirt.jpg");
    background-size: cover;
    background-position: center;
  }

  .thongs-image {
    height: 100%;
    background-image: url("../../../public/images/eshop/string.png");
    background-size: cover;
    background-position: center;
  }

  .tee-shirts-image-overlay {
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    transition: opacity 0.5s ease;

    img {
      height: 175px;
      opacity: 1;
    }

    &:hover {
      opacity: 0;
    }
  }

  .product-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 2.4vh;

    .price {
      font-family: "GT America Extended Black", sans-serif;
    }
  }
}