.header {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;

  img {
    max-width: 300px;
  }
}