.about-mobile {

  .header {
    height: 50px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    overflow: hidden;
  }

  .about-container {
    position: absolute;
    top: 50px;
    bottom: 50px;
    left: 0;
    right: 0;
    overflow: auto;

    .about-content {
      margin: 15px;
      display: flex;
      flex-direction: column;
      gap: 20px;

      p {
        font-size: 2.1em;
      }

      .calendar-img {
        height: 500px;
        background-image: url("../../../public/images/about/about-mobile.jpg");
        background-size: cover;
        background-position: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .text {
        width: 75%;
      }

      .button {
        position: relative;

        img {
          height: 14vh;
          position: absolute;
          right: 0;
        }
      }
    }
  }

  .footer {
    height: 50px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
  }
}