.about-desktop {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: nowrap;

  .about-content {
    width: 95%;
    flex-grow: 1;
    min-height: 2em;
    display: flex;
    gap: 50px;

    .images {
      height: 100%;
      flex: 5;
    }

    .text {
      display: flex;
      justify-content: center;
      flex: 3;

      .text-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;

        p {
          font-size: 4.3vh;
        }

        a {
          color: black;
        }

        .details {
          display: flex;
          justify-content: space-evenly;
          align-items: center;

          .order-yours-container {
            flex: 3;
            display: flex;
            justify-content: center;

            .order-yours {
              height: 14vh;

              &:hover {
                cursor: pointer;
              }
            }
          }

          p {
            flex: 2;
            font-size: 2.5vh;

            a {
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}