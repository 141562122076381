.footer {
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;

  .footer-content {
    width: 300px;
    display: flex;
    justify-content: space-between;

    a {
      text-decoration: none;
      color: black;
      font-size: 30px;
    }

    .selected {
      font-family: "GT America Extended Black", sans-serif;
    }
  }
}