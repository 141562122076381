.eshop-desktop {
  height: 100%;
  display: flex;
  justify-content: center;

  .header {
    position: fixed;
    top: 0;
    z-index: 1;
  }

  .footer {
    position: fixed;
    bottom: 0;
  }

  .eshop-content {
    width: 90%;
    margin: 100px 0;
    display: flex;
    flex-wrap: wrap;

    .product {
      flex-basis: 23%;
      margin-right: 20px;
      padding-bottom: 20px;
    }

    .product:nth-child(4n) {
      margin-right: 0;
    }

    .product:nth-child(5n) {
      padding-bottom: 100px;
    }
  }
}