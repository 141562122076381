.eshop-mobile {
  .header {
    position: fixed;
    top: 0;
  }

  .eshop-container {
    position: absolute;
    top: 50px;
    bottom: 50px;
    left: 0;
    right: 0;
    overflow: auto;
  }

  .eshop-content {
    margin: 0 15px;
    display: flex;
    flex-direction: column;

    .product {
      width: 100%;
      height: 70vh;
      margin-bottom: 20px;
    }
  }

  .footer {
    position: fixed;
    bottom: 0;
  }
}