.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;

  .modal-container {
    position: relative;
    height: 100%;
    width: 60%;
    background-color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;

    .xmark {
      position: absolute;
      top: 10px;
      right: -10px;
      height: 60px;

      &:hover {
        cursor: pointer;
      }
    }

    .modal-content {
      height: 70%;
      width: 85%;
      display: flex;
      gap: 5%;

      .images {
        flex: 1;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      .text-button {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .button {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 40vh;
            height: auto;
          }
        }

        .posters {
          flex: 1;
          display: flex;
          gap: 5px;

          div {
            flex: 1;
            display: flex;
            gap: 5px;
            background-size: cover;
          }

          .posters1 {
            flex: 4;
            flex-direction: column;

            .posters11 {
              flex: 4;

              .chancel {
                background-image: url("../../../public/images/eshop/posters/chancel-small.jpg");
              }

              .lisa {
                background-image: url("../../../public/images/eshop/posters/lisa-small.jpg");
              }

              .nasi {
                background-image: url("../../../public/images/eshop/posters/nasi-small.jpg");
              }

              div:hover {
                cursor: pointer;
              }
            }

            .posters12 {
              flex: 3;

              .sabi {
                background-image: url("../../../public/images/eshop/posters/sabi-small.jpg");
              }

              .margarita {
                background-image: url("../../../public/images/eshop/posters/margarita-small.jpg");
              }

              div:hover {
                cursor: pointer;
              }
            }
          }

          .posters2 {
            flex: 3;
            flex-direction: column;

            .posters21 {
              flex: 3;

              .jasmin {
                flex: 2;
                background-image: url("../../../public/images/eshop/posters/jasmin-small.jpg");
              }

              .lara {
                background-image: url("../../../public/images/eshop/posters/lara-small.jpg");
              }

              div:hover {
                cursor: pointer;
              }
            }

            .posters22 {
              flex: 4;

              .misha {
                background-image: url("../../../public/images/eshop/posters/misha-small.jpg");
              }

              .audrey {
                background-image: url("../../../public/images/eshop/posters/audrey-small.jpg");
              }

              div:hover {
                cursor: pointer;
              }
            }
          }
        }

        .text {
          flex: 1;
          display: flex;
          flex-direction: column;
          gap: 20px;

          .title-price {
            display: flex;
            justify-content: space-between;

            p {
              font-size: 1.8vw;
            }

            .title, .details {
              white-space: pre-line;
            }

            .price {
              white-space: nowrap;
            }
          }

          p {
            font-size: 1.5vw;
          }
        }
      }
    }
  }
}