.product {
  .xmark {
    position: absolute;
    right: -20px;
    z-index: 1;
    height: 50px;
  }

  .product-container {
    position: absolute;
    top: 0;
    bottom: 50px;
    left: 0;
    right: 0;
    overflow: auto;
    color: white;
    background-color: black;

    .product-content {
      margin: 50px 15px 10px;

      .image-detail {
        width: 100%;
        display: flex;
        justify-content: center;

        img {
          height: 60vh;
          object-fit: cover;
        }
      }

      .title-price {
        margin-top: 20px;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;

        p {
          font-size: 25px;
        }

        .title, .details {
          white-space: pre-line;
        }

        .price {
          white-space: nowrap;
          font-weight: bold;
        }
      }

      .description-button {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          font-size: 12px;
        }

        img {
          width: 45vw;
          height: auto;
        }
      }
    }
  }

  .footer {
    height: 50px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
  }
}